import React, { useLayoutEffect, useRef } from "react";
import { Table } from 'antd';
import Header from './header';
import '../../../../assets/stylesheets/components/_widget.scss';
import useTableData from '../../../hooks/useTableData.js';
import { formatDateWithTime } from "../../../utils/date_formater";
import useChannel from "./reload";



const mapFunction = (item, index) => {
  return {
    key: (index + 1).toString(),
    head_count_round: item.head_count_round_name,
    head_count_round_path: item.head_count_round_path,
    total: item.total,
    in_count: item.in_count,
    not_in_count: item.not_in_count,
    start_time: formatDateWithTime(item.start_time),
    end_time: formatDateWithTime(item.end_time),
    all_inmates_modal_path: item.all_inmates_modal_path,
    in_count_path: item.in_count_path,
    not_in_count_path: item.not_in_count_path,
    approve: item.complete_path
  };
};

const HeadCount = ({ setModal }) => {

  const columns = [
    {
      title: 'Head Count Round',
      dataIndex: 'head_count_round',
      key: 'head_count_round',
      render: (text, record) => <a href={record.head_count_round_path} >{text}</a>,
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      render: (text, record) => <a onClick={() => setModal(true)} className="table-action" data-toggle="head-count-modal" data-target="head-count-modal" data-remote="true" href={record.all_inmates_modal_path} >{text}</a>,
    },
    {
      title: 'In Count',
      dataIndex: 'in_count',
      key: 'in_count',
      render: (text, record) => <a onClick={() => setModal(true)}  className="table-action" data-toggle="head-count-modal" data-target="head-count-modal" data-remote="true" href={record.in_count_path} >{text}</a>,

    },
    {
      title: 'Not In Count',
      dataIndex: 'not_in_count',
      key: 'not_in_count',
      render: (text, record) => <a onClick={() => setModal(true)} className="table-action" data-toggle="head-count-modal" data-target="head-count-modal" data-remote="true" href={record.not_in_count_path} >{text}</a>,

    },
    {
      title: 'Start Time',
      dataIndex: 'start_time',
      key: 'start_time',
    },
    {
      title: 'End Time',
      dataIndex: 'end_time',
      key: 'end_time',
    },
    {
      title: 'Approve',
      dataIndex: 'approve',
      key: 'approve',
      render: (text, record) => <a onClick={() => setModal(true)} className="table-action" data-toggle="head-count-modal" data-target="head-count-modal" data-remote="true" href={record.approve} ><span>Approve</span></a>
    },
  ];


  const { tableData, title, fetchData } = useTableData("/dashboard/head_counts.json", mapFunction, "head_counts", "HEAD COUNT ALERTS");
  useChannel("HeadCountUpdateChannel", fetchData)

  const elementRef = useRef(null);

  useLayoutEffect(() => {
    const calcHeight = () => {
      const refheight = elementRef.current.offsetHeight;
      const thead = document.querySelector('#head_count .ant-table-thead');
      if (thead) thead.style.top = `${refheight}px`;
    };

    const observer = new MutationObserver(calcHeight);
    observer.observe(elementRef.current, {
      attributes: true,
      childList: true,
      subtree: true,
      characterData: true,
    });

    const handleResize = () => {
      calcHeight();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      observer.disconnect();
    };
  }, []);

  const hasRecords = tableData && tableData.length > 0;

  return (
    <div id="head_count">
      <Header title={title} ref={elementRef} approveAll={ hasRecords } setModal={ setModal } />
      <Table
        dataSource={tableData}
        columns={columns}
        pagination={false}
        locale={{
          emptyText: 'There Are No Unapproved Head Count Alerts',
        }}
      />
    </div>
  )
}

export default HeadCount;

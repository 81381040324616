import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Table, Button } from 'antd';
import Header from './header';
import '../../../../assets/stylesheets/components/_widget.scss';
import useTableData from '../../../hooks/useTableData.js';
import useChannel from "./reload";
import { calculateColorAndDuration } from './utils';

const mapFunction = (item, index) => {
  return {
    key: (index + 1).toString(),
    cell: item.cell_name,
    facility: item.display_name,
    last_observed_at: item.last_observed_at,
    next_observed_at: item.next_observed_at,
    yellow_threshold: item.yellow_threshold,
    color: item.color
  };
};

const CellObservations = () => {
  const [showGreenRecords, setShowGreenRecords] = useState(false);
  const [pastDueCount, setPastDueCount] = useState(0);

  const columns = [
    {
      title: 'Facility',
      dataIndex: 'facility',
      key: 'facility',
    },
    {
      title: 'Cell',
      dataIndex: 'cell',
      key: 'cell',
    },
    {
      title: 'Time Past Due',
      dataIndex: 'time',
      key: 'time',
    },
  ];

  const { tableData: rawTableData, title, fetchData } = useTableData("/dashboard/cell_observations.json", mapFunction, "cells");
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const run = () => {
      const result = rawTableData.map(item => {
        const { color, time } = calculateColorAndDuration(item);
        return { ...item, color, time }
      }).filter(item => showGreenRecords || item.color !== "green");
      setTableData(result);
    }
    const timer = setInterval(run, 1000);
    run();
    return () => clearInterval(timer);
  }, [rawTableData, showGreenRecords, setTableData])

  useLayoutEffect(() => {
    const interval = setInterval(() => {
      const redCellSize = document.querySelectorAll('#cell_observation .past-due-red').length;
      setPastDueCount(redCellSize);
    }, 1000);

    return () => clearInterval(interval);
  }, [rawTableData]);

  useChannel("CellObservationUpdateChannel", fetchData);
  const elementRef = useRef(null);

  useLayoutEffect(() => {
    const calcHeight = () => {
      const refheight = elementRef.current.offsetHeight;
      const thead = document.querySelector('#cell_observation .ant-table-thead');
      if (thead) thead.style.top = `${refheight}px`;
    };

    const observer = new MutationObserver(calcHeight);
    observer.observe(elementRef.current, {
      attributes: true,
      childList: true,
      subtree: true,
      characterData: true,
    });

    const handleResize = () => {
      calcHeight();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      observer.disconnect();
    };
  }, []);

  const toggleGreenRecords = () => {
    setShowGreenRecords(!showGreenRecords);
  };

  return (
    <div id="cell_observation">
      <Header title={title} pastDue={pastDueCount} ref={elementRef} toggleGreenRecords={ toggleGreenRecords }  />
      <Table
        rowClassName={(record) => {
          switch (record.color) {
            case "red":
              return "past-due-red";
            case "yellow":
              return "past-due-yellow";
            case "green":
              return "past-due-green";
          }
        }}
        dataSource={tableData}
        columns={columns}
        pagination={false}
      />
    </div>
  );
}

export default CellObservations;

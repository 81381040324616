const leftPad = (num) => {
    const str = String(num);
    return "0".repeat(Math.max(0, 2 - str.length)) + str;
}
const secondsInADay = 86400000, secondsInAHour = 3600000;
const maybeJoin = (left, right) => left === "" ? right : `${left} ${right}`;
export const calculateColorAndDuration = (record) => {
    const yellowThreshold = record.yellow_threshold,
        countFrom = new Date(record.next_observed_at).getTime(),
        localNow = new Date(),
        now = new Date(Date.UTC(localNow.getUTCFullYear(), localNow.getUTCMonth(), localNow.getUTCDate(), localNow.getUTCHours(), localNow.getUTCMinutes(), localNow.getUTCSeconds())),
        behind = now > countFrom;
    let timeDifference = behind ? (now - countFrom) : (countFrom - now);
    let color = "red"
    if (!behind && timeDifference > 60000) {
        if (timeDifference < yellowThreshold * 1000) {
            color = "yellow"
        } else {
            color = "green"
        }
    }
    const days = Math.floor(timeDifference / secondsInADay);
    timeDifference -= days * secondsInADay;
    const hours = Math.floor(timeDifference / secondsInAHour);
    timeDifference -= hours * secondsInAHour;
    const mins = Math.floor(timeDifference / 60000);
    timeDifference -= mins * 60000;
    const secs = Math.floor(timeDifference / 1000);
    let time = "";
    if (days > 0) {
        if (days == 1) {
            time = `${days} day`;
        } else {
            time = `${days} days`;
        }
    }
    if (hours > 0) {
        if (hours == 1) {
            time = maybeJoin(time, `${hours} hour`);
        } else {
            time = maybeJoin(time, `${hours} hours`);
        }
    }
    time = maybeJoin(time, `${leftPad(mins)}:${leftPad(secs)}`);
    if (record.inmate_not_present) {
        time = record.location_name;
    }
    return { color, time: time };
}

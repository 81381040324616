import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const useTableData = (url, mapFunction, dataKey, titleKey = "") => {
  const [tableData, setTableData] = useState([]);
  const [title, setTitle] = useState("");
  const [pastDueCount, setPastDueCount] = useState(0);

  const fetchData = useCallback(async () => {
    try {
      const token = document.querySelector('meta[name="csrf-token"]').content;
      const response = await axios.get(url, {
        headers: {
          "X-CSRF-Token": token,
        },
      });
      const dataArray = response.data[dataKey];
      const mappedData = dataArray.map((item, index) => ({
        ...mapFunction(item, index, response.data),
        key: item.id || index
      }));
      setTableData(mappedData);
      response.data.cell_observation_nomenclature ? setTitle(response.data.cell_observation_nomenclature) :
        response.data.iso_observation_nomenclature ? setTitle(response.data.iso_observation_nomenclature)
          : setTitle(titleKey)

      setPastDueCount(mappedData.filter(item => item.color === "red").length);

    } catch (error) {
      console.error(error);
    }
  }, [url, mapFunction, dataKey, titleKey]);

  useEffect(() => {
    fetchData();

    const interval = setInterval(() => {
      fetchData();
    }, 1 * 60 * 1000);

    return () => clearInterval(interval);
  }, [fetchData]);

  return {
    tableData,
    title,
    pastDueCount,
    fetchData,
  };
}

export default useTableData;

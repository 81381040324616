import React, { useLayoutEffect, useRef } from "react";
import { Table } from 'antd';
import Header from './header.js';
import '../../../../assets/stylesheets/components/_widget.scss';
import useTableData from '../../../hooks/useTableData.js';
import useLogApi from '../../../hooks/useLogApi.js';
import useChannel from "./reload.js";
import { formatDateNoTimeZone } from "../../../utils/date_formater.js";

const columns = [
  {
    title: 'Inmate',
    dataIndex: 'inmate',
    key: 'inmate',
    render: (text, record) => <a href={record.inmate_path}>{text}</a>,
  },
  {
    title: 'First Missed/Rejected',
    dataIndex: 'first_missed_rejected',
    key: 'first_missed_rejected',
  },
  {
    title: 'Last Missed/Rejected',
    dataIndex: 'last_missed_rejected',
    key: 'last_missed_rejected',
  },
  {
    title: 'Count',
    dataIndex: 'count',
    key: 'count',
    render: (text) => <a href="/hunger_strikes">{text}</a>,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
];

const mapFunction = (item, index) => {
  const firstHungerStrike = item.inmate_hunger_strikes && item.inmate_hunger_strikes[0];
  const firstMissedRejected = firstHungerStrike
    ? `${formatDateNoTimeZone(new Date(firstHungerStrike.date))} - ${firstHungerStrike.activity_name}`
    : 'N/A';

  return {
    key: index.toString(),
    inmate: item.full_name,
    inmate_path: item.inmate_path,
    first_missed_rejected: firstMissedRejected,
    last_missed_rejected: `${formatDateNoTimeZone(new Date(item.date))} - ${item.meal_activity}`,
    count: item.rejected_count,
    status: item.missed ? 'Missed' : 'Rejected',
  };
};

const HungerStrikes = () => {
  const { tableData, title, fetchData } = useTableData(
    "/dashboard/hunger_strikes.json",
    mapFunction,
    "hunger_strikes",
    "HUNGER STRIKES"
  );
  useChannel("HungerStrikeUpdateChannel", fetchData);
  useLogApi("/dashboard/hunger_strikes.json");

  const elementRef = useRef(null);

  useLayoutEffect(() => {
    const calcHeight = () => {
      const refHeight = elementRef.current.offsetHeight;
      const thead = document.querySelector('#hunger_strikes .ant-table-thead');
      if (thead) thead.style.top = `${refHeight}px`;
    };

    const observer = new MutationObserver(calcHeight);
    observer.observe(elementRef.current, {
      attributes: true,
      childList: true,
      subtree: true,
      characterData: true,
    });

    const handleResize = () => {
      calcHeight();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      observer.disconnect();
    };
  }, []);

  const hasRecords = tableData && tableData.length >= 5;

  return (
    <div id="hunger_strikes">
      <Header title={title} ref={elementRef} seeAll={hasRecords} />
      <Table
        dataSource={tableData}
        columns={columns}
        pagination={false}
        seeAll={hasRecords}
        locale={{
          emptyText: 'There Are No Hunger Strikes',
        }}
      />
    </div>
  );
};

export default HungerStrikes;

import { useState, useEffect } from 'react';
import axios from 'axios';

const useLogAPI = (url, interval = 60000) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(url);
        setData(response.data);
      } catch (error) {
        console.error(`Error fetching data from API: ${error}`);
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, interval);

    return () => clearInterval(intervalId);
  }, [url, interval]);

  useEffect(() => {
    if (data) {
      console.log(data);
    }
  }, [data]);

  return data;
};

export default useLogAPI;

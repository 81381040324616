import React, { useLayoutEffect, useRef } from "react";
import { Table } from 'antd';
import Header from './header';
import '../../../../assets/stylesheets/components/_widget.scss';
import useTableData from '../../../hooks/useTableData.js';
import { formatDate, getTime } from "../../../utils/date_formater";
import useChannel from "./reload";



const mapFunction = (item, index) => {
  console.log(item.approved_at)
  return {
    key: (index + 1).toString(),
    meal: item.activity_name,
    accepted: item.accepted_inmates_count,
    accepted_meal_round_path: item.accepted_meal_round_path,
    rejected: item.rejected_inmates_count,
    rejected_meal_round_path: item.rejected_meal_round_path,
    not_offered: item.inmates_count,
    not_offered_meal_round_path: item.not_offered_meal_round_path,
    date: formatDate(item.at),
    first_served: getTime(item.first_activity_changes),
    last_served: getTime(item.last_activity_changes),
    approved: item.approved_at != null,
    complete_modal_meal_round_path: item.complete_modal_meal_round_path
  };
};

const MealPass = ({ setModal }) => {

  const columns = [
    {
      title: 'Meal',
      dataIndex: 'meal',
      key: 'meal',

    },
    {
      title: 'Accepted',
      dataIndex: 'accepted',
      key: 'accepted',
      render: (text, record) => <a onClick={ ()=>setModal(true) } className="table-action" data-toggle="meals-modal" data-target="meals-modal" data-remote="true" href={ record.accepted_meal_round_path } > { text } </a>,
    },
    {
      title: 'Rejected',
      dataIndex: 'rejected',
      key: 'rejected',
      render: (text, record) => <a onClick={ ()=>setModal(true) } className="table-action" data-toggle="meals-modal" data-target="meals-modal" data-remote="true" href={ record.rejected_meal_round_path } > { text } </a>,
    },
    {
      title: 'Not Offered',
      dataIndex: 'not_offered',
      key: 'not_offered',
      render: (text, record) => <a onClick={ ()=>setModal(true) } className="table-action" data-toggle="meals-modal" data-target="meals-modal" data-remote="true" href={ record.not_offered_meal_round_path } > { text } </a>,
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'First Served',
      dataIndex: 'first_served',
      key: 'first_served',
    },
    {
      title: 'Last Served',
      dataIndex: 'last_served',
      key: 'last_served',
    },
    {
      title: 'Approve',
      dataIndex: 'approved',
      key: 'approved',
      render: (text, record) => <a onClick={() => setModal(true)} className="table-action" data-toggle="meal-complete-modal" data-target="meal-complete-modal" data-remote="true" href={record.complete_modal_meal_round_path} ><span>Approve</span></a>
    }
  ];

  const { tableData, title, fetchData } = useTableData( "/dashboard/meal_rounds.json", mapFunction, "lines", "MEAL PASS" );
  useChannel( "MealPassUpdateChannel", fetchData )

  const elementRef = useRef( null );

  useLayoutEffect(() => {
    const calcHeight = () => {
      const refheight = elementRef.current.offsetHeight;
      const thead = document.querySelector( '#meal_pass .ant-table-thead' );
      if (thead) thead.style.top = `${refheight}px`;
    };

    const observer = new MutationObserver( calcHeight );
    observer.observe( elementRef.current, {
      attributes: true,
      childList: true,
      subtree: true,
      characterData: true,
    });

    const handleResize = () => {
      calcHeight();
    };

    window.addEventListener( 'resize', handleResize );

    return () => {
      window.removeEventListener( 'resize', handleResize );
      observer.disconnect();
    };
  }, []);

  return (
    <div id="meal_pass">
      <Header title={ title } ref={ elementRef }/>
      <Table
        dataSource={ tableData }
        columns={ columns }
        pagination={false}
        locale={{
          emptyText: 'No Meal Rounds Have Started',
        }}
      />
    </div>
  )
}

export default MealPass;

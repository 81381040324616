import React, { useLayoutEffect, useRef } from "react";
import { Table } from 'antd';
import Header from './header';
import '../../../../assets/stylesheets/components/_widget.scss';
import useTableData from '../../../hooks/useTableData.js';
import { formatDateWithTime } from "../../../utils/date_formater";
import useChannel from "./reload";



const mapFunction = (item, index) => {
  return {
    inmate_cell_facility: item.inmate_cell_facility,
    item: item.name,
    user: item.user_name,
    note: item.note,
    issued_at: formatDateWithTime(item.issued_at),
    facility: item.facility
  };
};

const ItemIssues = () => {
  const columns = [
    {
      title: 'Inmate or Cell',
      dataIndex: 'inmate_cell_facility',
      key: 'inmate_cell_facility',
    },
    {
      title: 'Item',
      dataIndex: 'item',
      key: 'item',
    },
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
    },
    {
      title: 'Note',
      dataIndex: 'note',
      key: 'note',
    },
    {
      title: 'Issued at',
      dataIndex: 'issued_at',
      key: 'issued_at',
    },
    {
      title: 'Facility',
      dataIndex: 'facility',
      key: 'facility',
    },
  ];
  const { tableData, title, fetchData } = useTableData("/dashboard/item_issues.json", mapFunction, "item_issues", "HIGH RISK ITEMS");
  useChannel("ItemIssuesUpdateChannel", fetchData)

  const elementRef = useRef(null);

  useLayoutEffect(() => {
    const calcHeight = () => {
      const refheight = elementRef.current.offsetHeight;
      const thead = document.querySelector('#item_issues .ant-table-thead');
      if (thead) thead.style.top = `${refheight}px`;
    };

    const observer = new MutationObserver(calcHeight);
    observer.observe(elementRef.current, {
      attributes: true,
      childList: true,
      subtree: true,
      characterData: true,
    });

    const handleResize = () => {
      calcHeight();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      observer.disconnect();
    };
  }, []);
  return (
    <div id="item_issues">
      <Header title={title} ref={ elementRef }/>
      <Table
        dataSource={tableData}
        columns={columns}
        pagination={false}
        locale={{
          emptyText: 'There Are No High Risk Items Checked Out',
        }}
      />
    </div>
  )
}

export default ItemIssues;

import React from "react"
import LiveEvents from "../../LiveEvents"
import '../../../../assets/stylesheets/components/_live.scss'

export default (props) => (
  <div>
    <div className="live-image-wrapper" >
      <img className="jailcore-img" src="/assets/jailcore-live-transparent.png" alt="jailcore live" style={{ marginTop: '.5em' }}/>
    </div>
    <LiveEvents
      showFilters={false}
      events_hash={props.events_hash}
      events={props.events}
      timezone={props.timezone}
      isWidget={props.isWidget}
    />
  </div>
)

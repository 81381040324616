import React, { useLayoutEffect, useRef } from 'react';
import { Table } from 'antd';
import Header from './header.js';
import '../../../../assets/stylesheets/components/_widget.scss';
import useTableData from '../../../hooks/useTableData.js';
import { formatDateWithTime } from '../../../utils/date_formater.js';
import useChannel from './reload.js';

const mapFunction = (item, index) => {
  return {
    recorded_at: formatDateWithTime(item.recorded_at),
    note: item.note,
    user_name: item.user_name,
    facilities: item.facilities,
  };
};

const DailyLogs = ({ setModal }) => {
  const columns = [
    {
      title: 'Facilities',
      dataIndex: 'facilities',
      key: 'facilities',
    },

    {
      title: 'Note',
      dataIndex: 'note',
      key: 'note',
    },
    {
      title: 'Oficer',
      dataIndex: 'user_name',
      key: 'user_name',
    },
    {
      title: 'Recorded At',
      dataIndex: 'recorded_at',
      key: 'recorded_at',
    },
  ];

  const { tableData, title, fetchData } = useTableData(
    '/dashboard/daily_logs.json',
    mapFunction,
    'log_entries',
    'DAILY SHIFT/LOGS'
  );
  useChannel('DailyLogsUpdateChannel', fetchData);

  const elementRef = useRef(null);

  useLayoutEffect(() => {
    const calcHeight = () => {
      const refheight = elementRef.current.offsetHeight;
      const thead = document.querySelector('#daily_logs .ant-table-thead');
      if (thead) thead.style.top = `${refheight}px`;
    };

    const observer = new MutationObserver(calcHeight);
    observer.observe(elementRef.current, {
      attributes: true,
      childList: true,
      subtree: true,
      characterData: true,
    });

    const handleResize = () => {
      calcHeight();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      observer.disconnect();
    };
  }, []);

  return (
    <div id='daily_logs'>
      <Header
        title={title}
        ref={elementRef}
        newEntry={true}
        setModal={setModal}
        locale={{
          emptyText: 'There are no Logs to show.',
        }}
      />
      <Table dataSource={tableData} columns={columns} pagination={false} />
    </div>
  );
};

export default DailyLogs;

const stripTimeZone = (dateISO) => {
  return dateISO ? dateISO.slice(0, -6) : null;
};

export const formatDate = (dateISO) => {
  if (dateISO === null) return '';

  const strippedDate = stripTimeZone(dateISO);
  const date = new Date(strippedDate);

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;

  return `${formattedMonth}/${formattedDay}/${year}`;
}

export const formatDateWithTime = (dateISO) => {
  if (dateISO === null) return '';

  const strippedDate = stripTimeZone(dateISO);
  const date = new Date(strippedDate);

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedHours = hours < 10 ? `0${hours}` : hours;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${formattedMonth}/${formattedDay}/${year} ${formattedHours}:${formattedMinutes}`;
}


export const getTime = (dateISO) => {
  if (dateISO === null) return '';

  const strippedDate = stripTimeZone(dateISO);
  const date = new Date(strippedDate);

  const hours = date.getHours();
  const minutes = date.getMinutes();

  const formattedHours = hours < 10 ? `0${hours}` : hours;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${formattedHours}:${formattedMinutes}`;
};

export const formatDateWithTimeNoTimeZone = (dateString) => {
  if (!dateString) return '';

  const date = new Date(dateString);

  if (isNaN(date)) return '';

  const day = date.getUTCDate();
  const month = date.getUTCMonth() + 1;
  const year = date.getUTCFullYear();
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();

  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedHours = hours < 10 ? `0${hours}` : hours;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${formattedMonth}/${formattedDay}/${year} ${formattedHours}:${formattedMinutes}`;
};


export const formatDateNoTimeZone = (dateString) => {
  if (!dateString) return '';

  const date = new Date(dateString);

  if (isNaN(date)) return '';

  const day = date.getUTCDate();
  const month = date.getUTCMonth() + 1;
  const year = date.getUTCFullYear();

  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;

  return `${formattedMonth}/${formattedDay}/${year}`;
};

import React, { useLayoutEffect, useRef, useState, useEffect } from 'react';
import { Table, Button } from 'antd';
import Header from './header';
import '../../../../assets/stylesheets/components/_widget.scss';
import useTableData from '../../../hooks/useTableData.js';
import useChannel from './reload';
import useLogApi from '../../../hooks/useLogApi.js';
import { calculateColorAndDuration } from './utils.js'


const mapFunction = (item, index) => {
  return {
    key: (index + 1).toString(),
    inmate: item.inmate_name,
    cell: item.cell_name,
    facility: item.facility_name,
    last_observed_at: item.last_observed_at,
    next_observed_at: item.next_observed_at,
    yellow_threshold: item.yellow_threshold,
    color: item.color,
    inmate_path: item.inmate_path,
    inmate_not_present: item.inmate_not_present,
    location_name: item.location_name
  };
};

const SpecialNeedsWatch = () => {
  const [showGreenRecords, setShowGreenRecords] = useState(false);
  const [pastDueCount, setPastDueCount] = useState(0);


  const columns = [
    {
      title: 'Inmate',
      dataIndex: 'inmate',
      key: 'inmate',
      render: (text, record) => <a href={record.inmate_path}>{text}</a>,
    },
    {
      title: 'Facility',
      dataIndex: 'facility',
      key: 'facility',
    },
    {
      title: 'Cell',
      dataIndex: 'cell',
      key: 'cell',
    },
    {
      title: 'Time Past Due',
      dataIndex: 'time',
      key: 'time',
    },
  ];

  const { tableData: rawTableData, title, fetchData } = useTableData(
    '/dashboard/special_needs_watch.json',
    mapFunction,
    'special_needs_inmates'
  );
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    const run = () => {
      const result = rawTableData.map(item => {
        const { color, time } = calculateColorAndDuration(item);
        return { ...item, color, time }
      }).filter(item => showGreenRecords || item.color !== "green");
      setTableData(result);
    }
    const timer = setInterval(run, 1000);
    run();
    return () => clearInterval(timer);
  }, [rawTableData, showGreenRecords, setTableData])
  useChannel('InmateObservationUpdateChannel', fetchData);

  useLogApi('/dashboard/special_needs_watch.json')

  const elementRef = useRef(null);

  useLayoutEffect(() => {
    const calcHeight = () => {
      const refheight = elementRef.current.offsetHeight;
      const thead = document.querySelector('#special_needs .ant-table-thead');
      if (thead) thead.style.top = `${refheight}px`;
    };

    const observer = new MutationObserver(calcHeight);
    observer.observe(elementRef.current, {
      attributes: true,
      childList: true,
      subtree: true,
      characterData: true,
    });

    const handleResize = () => {
      calcHeight();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      observer.disconnect();
    };
  }, []);

  useLayoutEffect(() => {
    const interval = setInterval(() => {
      const redCellSize = document.querySelectorAll('#special_needs .past-due-red').length;
      setPastDueCount(redCellSize);
    }, 1000);

    return () => clearInterval(interval);
  }, [rawTableData]);

  const toggleGreenRecords = () => {
    setShowGreenRecords(!showGreenRecords);
  };

  return (
    <div id='special_needs'>
      <Header title={title} pastDue={pastDueCount} ref={elementRef} toggleGreenRecords={ toggleGreenRecords }  />
      <Table
        rowClassName={(record) => {
          if (record.inmate_not_present) return 'gray'
          switch (record.color) {
            case "red":
              return "past-due-red";
            case "yellow":
              return "past-due-yellow";
            case "green":
              return "past-due-green";
          }
        }}
        dataSource={tableData} // Use filtered data
        columns={columns}
        pagination={false}
      />
    </div>
  );
};

export default SpecialNeedsWatch;
